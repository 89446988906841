import axios from "axios";

import error                                 from "_common/error";
import { getUserData, storeUserDataCookie }  from "_common/utils/user";
import { APP_VERSION, configs, DEVICE_TYPE } from "_config/index";

export const refreshTokenLogic = async originalRequest => {
	let refreshToken = originalRequest.refreshToken;
	let accessToken  = originalRequest.accessToken;
	let oldData      = getUserData ();

	try {
		const deviceId = originalRequest.headers["device-id"];

		const headers = {
			"device-type"   : DEVICE_TYPE,
			"app-version"   : APP_VERSION,
			"device-id"     : originalRequest.headers["device-id"],
			"authorization" : `Bearer ${ accessToken }`
		};

		const response = await axios ( {
			method  : "post",
			url     : `${ configs.AUTH }auth/refresh`,
			data    : { refreshToken, accessToken },
			headers : headers
		} );

		const newAccessToken  = response.data.accessToken; // API returns a new access token
		const newRefreshToken = response.data.refreshToken; // API returns a new refresh token

		let userId;

		if ( typeof window !== undefined ) {
			userId = response.data.isVerified ? oldData.userId : null;
		}

		const data = {
			accessToken  : newAccessToken,
			refreshToken : newRefreshToken,
			deviceId,
			tokenType    : oldData?.tokenType || "Bearer",
			isNew        : oldData?.isNew,
			locale       : oldData?.locale,
			userId,
			isVerified   : response.data.isVerified
		};

		// Retry the failed request with the new access token
		originalRequest.headers.authorization = `Bearer ${ newAccessToken }`;
		originalRequest.accessToken           = newAccessToken;
		originalRequest.refreshToken          = newRefreshToken;

		axios.defaults.headers.common["authorization"] = `Bearer ${ newAccessToken }`;

		storeUserDataCookie ( data );

		return axios ( originalRequest );
	} catch ( refreshError ) {
		// Handle refresh token expired error, logout the user
		if ( typeof window !== undefined ) {
			return {
				refreshTokenError: true
			};
		}
		throw new Error ( "REFRESH_TOKEN_EXPIRED", { cause: error } );
	}
};

export const handleForcedLogout = () => {
	// Perform any custom logic you need for forced logout, e.g., redirect to logout page
	window.location.replace ( `/logout?forceLogout=${ true }&prevLocation=${ window.location.pathname }` );
	axios.defaults.headers.common = {};
};
