const error = err => {
	let obj = {
		type       : "custom",
		code       : 500,
		message    : "server error",
		displayMsg : "Something went wrong, please try again"
	};

	if ( typeof err === "string" ) {
		obj["displayMsg"] = err;
	}

	if ( typeof err === "object" ) {

		if ( err.type === "custom" )
			return err;

		const response = err?.response?.data;

		if ( response?.userMessage ) {

			if ( isJSON ( response.userMessage ) ) {
				let data = JSON.parse ( response.userMessage );

				obj["code"]       = data.statusCode;
				obj["displayMsg"] = data.message;

			} else {
				obj["message"]    = response.developerMessage;
				obj["code"]       = response.statusCode;
				obj["displayMsg"] = response.userMessage;
			}

		} else if ( response?.error?.userMessage ) {
			let data = response.error;

			obj["message"]    = data.developerMessage;
			obj["code"]       = data.statusCode;
			obj["displayMsg"] = data.userMessage;
		} else if ( response?.message ) {
			obj["message"]    = response.developerMessage;
			obj["code"]       = response.statusCode;
			obj["displayMsg"] = response.message;
		} else if ( response?.errors?.length ) {
			let data = response.errors[0];

			obj["message"]    = data.developerMessage;
			obj["code"]       = data.statusCode;
			obj["displayMsg"] = data.userMessage;
		} else {
			obj["code"]    = err.status;
			obj["message"] = err.message;
			obj["stack"]   = err.stack;

			if ( err.message === "Network Error" ) {
				obj["code"]       = 503;
				obj["displayMsg"] = "Please check your internet connection";
			}
		}
	}

	return {
		type       : obj?.type || null,
		code       : obj?.code || null,
		message    : obj?.message || null,
		displayMsg : obj?.displayMsg || null
	};
};

function isJSON ( str ) {
	try {
		return ( JSON.parse ( str ) && !!str );
	} catch ( e ) {
		return false;
	}
}

export default error;
