import { createLogger } from "browser-bunyan";

const loggers = {};

const level = process.env.APP_ENV === "prod" ? "info" : "trace";

const create = file => {
	const logger = createLogger ( {
		name  : file,
		level : level
	} );

	loggers[file] = logger;

	return logger;
};

const logger = ( { file } ) => {
	if ( !loggers[file] )
		create ( file );

	return loggers[file];
};

export default logger;
